<template>
  <head>
    <title>{{ $t("Navbar.Home") }}</title>
  </head>
  <div class="home-view">
    <!-- <swiper
      :modules="modules"
      slides-per-view="1"
      :space-between="35"
      navigation
      :autoplay="{ delay: 10000 }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide> -->
    <video class="background-vid">
      <source :src="BackVideo" type="video/mp4" />
    </video>
    <!-- </swiper-slide>
      <swiper-slide v-for="(item, index) in about_imges" :key="index">
        <img class="background-vid" :src="item" />
        ></swiper-slide
      >
    </swiper> -->

    <!-- About us title -->
    <div class="about-us p-5" id="AboutUs">
      <!-- text -->
      <div
        class="w-100"
        :class="$i18n.locale === 'ar' ? 'text-end' : 'text-start'"
      >
        <div class="about-title hidden" v-in-view="'animate__fadeInRight'">
          <h1>
            <span style="color: whitesmoke">{{ $t("ASOOL") }}</span>
            {{ $t("PLUS") }}
          </h1>
        </div>
      </div>
      <img
        :src="logo_src"
        class="d-block about-img hidden"
        v-in-view="'animate__fadeInLeft'"
        alt="..."
      />
    </div>
    <div class="container">
      <div
        class="notification mb-2 mb-5 hidden"
        v-in-view="'animate__fadeInUp'"
      >
        <div class="notiglow"></div>
        <div class="notiborderglow"></div>
        <h2 class="notititle">{{ $t("AboutUs") }}</h2>
      </div>
      <div
        class="notification my-2 mb-5 hidden"
        v-in-view="'animate__fadeInUp'"
        v-for="(item, index) in letters[$i18n.locale]"
        :key="index"
      >
        <div class="notiglow"></div>
        <div class="notiborderglow"></div>
        <h4
          class="my-4 hidden notibody"
          v-in-view="'animate__fadeInBottomLeft'"
        >
          {{ item }}
        </h4>
      </div>
      <div
        class="notification my-2 mb-5 hidden"
        v-in-view="'animate__fadeInUp'"
      >
        <div class="notiglow"></div>
        <div class="notiborderglow"></div>
        <h4
          class="my-4 hidden notibody"
          v-in-view="'animate__fadeInBottomLeft'"
        >
          {{ $t("HDS") }}
          <a href="https://1hds.net/" class="nav-item" target="_blank">{{
            $t("GetToKnowHds")
          }}</a>
        </h4>
      </div>
    </div>
    <!-- Services -->
    <div class="w-100 bg-light">
      <div class="container p-5">
        <div class="sections">
          <div class="section-title">
            <div class="section-line hidden" v-in-view="'animate__fadeInRight'">
              <div class="title-static2">{{ $t("Services") }}</div>
              <div class="underline-static"></div>
            </div>
          </div>
        </div>
        <div class="my-5">
          <div
            class="row w-100 my-5 hidden"
            v-for="(item, index) in services[$i18n.locale]"
            :key="index"
            v-in-view="'animate__fadeInUp'"
          >
            <div
              class="col-lg-6 service-image"
              v-if="index % 2 == 0 || (index % 2 != 0 && screen_width <= 1025)"
            >
              <img :src="item.img" :alt="item.name" />
            </div>
            <div class="col-lg-6 service-info">
              <h1>{{ item.name }}</h1>
              <ul>
                <li v-for="(item2, index2) in item.points" :key="index2">
                  {{ item2 }}
                  <a
                    style="color: #d3b37d"
                    v-if="index == 2 && index2 == 3"
                    href="https://1hds.net/"
                    >{{ $t("GetToKnowHds") }}</a
                  >
                </li>
              </ul>
            </div>
            <div
              class="col-lg-6 service-image"
              v-if="index % 2 != 0 && screen_width >= 1025"
            >
              <img :src="item.img" :alt="item.name" />
            </div>
          </div>

          <!-- dividor -->
          <div
            class="dividor"
            v-if="index < Object.keys(data).length - 1"
          ></div>
        </div>
      </div>
    </div>
    <!-- Why us -->
    <div class="w-100 bg-light">
      <div class="container p-5">
        <div class="sections">
          <div class="section-title">
            <div class="section-line hidden" v-in-view="'animate__fadeInRight'">
              <div class="title-static2">{{ $t("WhyUs") }}</div>
              <div class="underline-static"></div>
            </div>
          </div>
        </div>
        <div class="my-5">
          <div
            class="row w-100 my-5 hidden"
            v-for="(item, index) in why[$i18n.locale]"
            :key="index"
            v-in-view="'animate__fadeInUp'"
          >
            <div
              class="col-lg-12 d-flex justify-content-start align-items-center"
            >
              <Icon :icon="item.icon" width="50" heighet="50" color="#d3b37d" />
              <div class="d-block mx-2" style="text-align: start">
                <h5>{{ item.name }}</h5>
                <h6>{{ item.desc }}</h6>
              </div>
            </div>
          </div>

          <!-- dividor -->
          <div
            class="dividor"
            v-if="index < Object.keys(data).length - 1"
          ></div>
        </div>
      </div>
    </div>
    <!-- Sections -->
    <div class="w-100 scroll-background">
      <!-- news -->
      <div
        class="container p-5 hidden"
        v-in-view="'animate__fadeInUp'"
        v-if="Object.keys(news).length > 0"
      >
        <div class="sections" v-if="Object.keys(news).length > 0">
          <div class="section-title">
            <div class="section-line hidden" v-in-view="'animate__fadeInRight'">
              <div class="title-static">{{ $t("Navbar.News") }}</div>
              <div class="underline-static"></div>
            </div>
          </div>
          <div
            class="section-title d-flex justify-content-end align-items-center"
          >
            <div class="section-line hidden" v-in-view="'animate__fadeInLeft'">
              <div class="title title-static" @click="$router.push('/news')">
                {{ $t("ShowAll") }}
              </div>
              <div class="underline"></div>
            </div>
          </div>
        </div>
        <div class="my-5" v-if="Object.keys(news).length > 0">
          <swiper
            :modules="modules"
            :slides-per-view="
              screen_width <= 700 ? '1' : screen_width <= 1025 ? '2' : '3'
            "
            :space-between="35"
            data-swiper-autoplay="10000"
            autoplay
            navigation
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(item, index) in news" :key="news[index].id">
              <news-cards
                :id="item.id"
                :title="$i18n.locale === 'ar' ? item.title_ar : item.title"
                :img="item.image"
                :text="$i18n.locale === 'ar' ? item.text_ar : item.text"
              ></news-cards>
            </swiper-slide>
          </swiper>

          <!-- dividor -->
          <div
            class="dividor"
            v-if="index < Object.keys(data).length - 1"
          ></div>
        </div>
      </div>
      <!-- projects -->
      <div
        class="container p-5 hidden"
        v-in-view="'animate__fadeInUp'"
        v-if="Object.keys(projects).length > 0"
      >
        <div class="sections" v-if="Object.keys(projects).length > 0">
          <div class="section-title">
            <div class="section-line hidden" v-in-view="'animate__fadeInRight'">
              <div class="title-static">{{ $t("Navbar.Projects") }}</div>
              <div class="underline-static"></div>
            </div>
          </div>
          <div
            class="section-title d-flex justify-content-end align-items-center"
          >
            <div class="section-line hidden" v-in-view="'animate__fadeInLeft'">
              <div
                class="title title-static"
                @click="$router.push('/projects')"
              >
                {{ $t("ShowAll") }}
              </div>
              <div class="underline"></div>
            </div>
          </div>
        </div>
        <div class="my-5" v-if="Object.keys(projects).length > 0">
          <swiper
            :modules="modules"
            :slides-per-view="
              screen_width <= 700 ? '1' : screen_width <= 1025 ? '2' : '3'
            "
            :space-between="35"
            data-swiper-autoplay="10000"
            autoplay
            navigation
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(item, index) in projects" :key="index">
              <ProjectCards
                class="m-3 hidden"
                v-in-view="'animate__backInUp'"
                :img="$store.state.server_images + item.image"
                :id="item.id"
                :title="$i18n.locale === 'ar' ? item.name_ar : item.name_en"
              ></ProjectCards>
            </swiper-slide>
          </swiper>

          <!-- dividor -->
          <div
            class="dividor"
            v-if="index < Object.keys(data).length - 1"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCards from "@/components/NewsCard/NewsCards.vue";
import ProjectCards from "@/components/ProjectCard/ProjectCards.vue";
import store from "@/store";
import axios from "axios";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Icon } from "@iconify/vue";

export default {
  name: "home-view",
  data() {
    return {
      news: {},
      data: [],
      projects: {},
      letters: {
        ar: {
          0: "في أصول بلس، نعتبر أنفسنا شركاء في بناء المستقبل. من خلال خبرتنا الواسعة في قطاع المقاولات داخل المملكة العربية السعودية.",
          1: "نقدم لكم حلولاً مهنية عالية الجودة لجميع أنواع المشاريع السكنية والتجارية.",
          2: "نحن ملتزمون بتقديم خدمات متميزة تحاكي تطلعات عملائنا، مع الحفاظ على أعلى المعايير العالمية في الجودة والأداء.",
        },
        en: {
          0: "At ASOOL PLUS, we consider ourselves partners in shaping the future. With extensive experience in the contracting sector across Saudi Arabia.",
          1: " We offer professional, high-quality solutions for all types of residential and commercial projects.",
          2: " We are committed to delivering exceptional services that meet our clients aspirations while maintaining the highest global standards of quality and performance.",
        },
      },
      about_imges: {
        0: require("@/assets/TestImages/AboutUs/img1.jpg"),
        1: require("@/assets/TestImages/AboutUs/img2.jpg"),
        2: require("@/assets/TestImages/AboutUs/img3.jpg"),
        3: require("@/assets/TestImages/AboutUs/img4.jpg"),
        4: require("@/assets/TestImages/AboutUs/img5.jpg"),
      },
      i: 0,
      services: {
        ar: {
          0: {
            name: "المقاولات السكنية",
            img: require("@/assets/Services/1.jpg"),
            points: [
              "بناء المنازل الفاخرة",
              "تجديد وتطوير المنازل والمباني السكنية",
              "تصميم وتنفيذ الفلل والقصور",
              "الأرضيات والدهانات والتشطيبات الداخلية",
              "الحدائق والمناظر الطبيعية",
            ],
          },
          1: {
            name: "المقاولات التجارية",
            img: require("@/assets/Services/2.jpg"),
            points: [
              "بناء الأبراج والمشاريع التجارية",
              "تجديد وتطوير المساحات المكتبية والمحال التجارية",
              "تنفيذ المشاريع الصناعية والمنشآت الكبرى",
              "تصميم وتنفيذ المراكز التجارية والمولات",
              "أعمال البنية التحتية",
            ],
          },
          2: {
            name: "الخدمات المتخصصة",
            img: require("@/assets/Services/3.jpg"),
            points: [
              "التكييف والتهوية والتدفئة (HVAC)",
              "األنظمة الكهربائية والسباكة",
              "الأعمال الهندسية المدنية",
              "النجارة والأعمال الخشبية",
              "استشارات البناء والتصميم",
            ],
          },
        },
        en: {
          0: {
            name: "Residential Contracting",
            img: require("@/assets/Services/1.jpg"),
            points: [
              "Luxury Home Construction",
              "Renovation and Refurbishment of Residential Properties",
              "Design and Construction of Villas and Palaces",
              "Flooring, Painting, and Interior Finishes",
              "Landscaping and Outdoor Design",
            ],
          },
          1: {
            name: "Commercial Contracting",
            img: require("@/assets/Services/2.jpg"),
            points: [
              "Construction of Towers and Commercial Projects",
              "Renovation and Development of Office Spaces and Retail Stores",
              "Execution of Industrial Projects and Large-Scale Facilities",
              "Design and Construction of Shopping Centers and Malls",
              "Infrastructure Works",
            ],
          },
          2: {
            name: "Specialized Services",
            img: require("@/assets/Services/3.jpg"),
            points: [
              "HVAC Systems (Heating, Ventilation, and Air Conditioning)",
              "Electrical and Plumbing Systems",
              "Civil Engineering Works",
              "Carpentry and Custom Woodworking",
              "Construction and Design Consultancy",
            ],
          },
        },
      },
      why: {
        ar: [
          {
            name: "احترافية عالية",
            desc: "نحن نقدم خبرة عالية في تنفيذ المشاريع المعقدة، مع اهتمام دقيق بأدق التفاصيل.",
            icon: "mdi:professional-hexagon",
          },
          {
            name: "التزام بالمواعيد",
            desc: "نحن نعلم أهمية الوقت، ونسعى لإنجاز المشاريع ضمن الجدول الزمني المتفق عليه.",
            icon: "mingcute:time-fill",
          },
          {
            name: "جودة عالمية",
            desc: "نلتزم باستخدام أفضل المواد وأحدث التقنيات لضمان جودة التنفيذ.",
            icon: "mdi:quality-high",
          },
          {
            name: "حلول مبتكرة",
            desc: "نبحث دائماً عن حلول مبتكرة تلبي احتياجات عملائنا وتتناسب مع تطورات السوق المحلي.",
            icon: "streamline:collaborations-idea-solid",
          },
          {
            name: "التوافق مع الأنظمة المحلية",
            desc: "نحن ملتزمون بجميع اللوائح والأنظمة المعمول بها في المملكة العربية السعودية، مما يضمن سير العمل وفقا للمواصفات القانونية والمعايير المحلية .",
            icon: "eos-icons:system-ok",
          },
        ],
        en: [
          {
            name: "High Professionalism",
            desc: "We offer extensive experience in executing complex projects with meticulous attention to detail.",
            icon: "mdi:professional-hexagon",
          },
          {
            name: "Timely Delivery",
            desc: "We understand the importance of time, and we strive to complete projects within the agreed-upon timelines.",
            icon: "mingcute:time-fill",
          },
          {
            name: "Global-Quality Standards",
            desc: "We are committed to using the best materials and the latest technologies to ensure superior project execution.",
            icon: "mdi:quality-high",
          },
          {
            name: "Innovative Solutions",
            desc: "We continuously seek innovative solutions to meet our clients needs and align with market developments.",
            icon: "streamline:collaborations-idea-solid",
          },
          {
            name: "Compliance with Local Regulations",
            desc: "We adhere to all regulations and standards in Saudi Arabia, ensuring that our projects meet local specifications and legal requirements.",
            icon: "eos-icons:system-ok",
          },
        ],
      },
      logo_src: require("@/assets/Logo/osoolLogo1.png"),
      screen_width: screen.width,
      BackVideo: require("@/assets/BackGround.mp4"),
    };
  },
  setup() {
    var x;
    const onSwiper = (swiper) => {
      x = swiper;
      swiper = x;
    };
    const onSlideChange = () => {
      x;
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y, Autoplay],
    };
  },
  components: {
    NewsCards,
    Swiper,
    ProjectCards,
    SwiperSlide,
    Icon,
  },
  // beforeRouteLeave(to, from, next) {
  //   // Stop the execution of WriteLetter and RemoveLetter methods
  //   clearTimeout(this.writeLetterTimeout);
  //   clearTimeout(this.removeLetterTimeout);
  //   next();
  // },

  methods: {
    hideLoadingScreen() {
      store.commit("hideLoadingScreen");
      store.state.isLoading = 1;
      const waitForVideo = setInterval(() => {
        const video = document.querySelector(".background-vid");
        video.removeAttribute("controls");
        if (video instanceof HTMLVideoElement) {
          clearInterval(waitForVideo); // Stop polling

          // Remove the muted attribute if it's present
          video.muted = true;

          // Ensure the video loops
          video.loop = true;

          // Attempt to play the video
          video.play().catch(() => {
            // If autoplay fails, wait for user interaction
            const playOnInteraction = () => {
              video.play();
              document.removeEventListener("click", playOnInteraction);
            };
            document.addEventListener("click", playOnInteraction);
          });
        }
      }, 100);
    },
    getNews() {
      axios
        .get(store.state.server + "news-index")
        .then((response) => {
          this.news = response.data;
          this.hideLoadingScreen();
          this.getProjects();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjects() {
      axios
        .get(store.state.server + "get-all-projects")
        .then((response) => {
          this.projects = response.data;
          this.AboutUsDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    AboutUsDelete() {
      setTimeout(() => {
        const element = document.getElementById("AboutUs");
        if (element) {
          element.classList.add("fade-out"); // Add the fade-out class to start the animation
          element.addEventListener("animationend", () => {
            element.remove(); // Remove the element after the animation ends
          });
        }
      }, 3000);
    },
  },
  directives: {
    inView: {
      mounted(el, binding) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                el.classList.add("animate__animated", binding.value);
                el.classList.remove("hidden");
                observer.unobserve(el); // Stop observing
              }
            });
          },
          { threshold: 0.1 } // Trigger when 10% of the element is visible
        );
        observer.observe(el);
      },
    },
  },

  mounted() {
    store.state.isLoading = 0;
    this.getNews();
  },
};
</script>

<style scoped>
/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box; /* Ensure consistent box sizing */
}

.news-text {
  background-color: #1919196d;
  color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
}

.my-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.322);
}

.sections {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  transition: 0.5s ease-in-out;
  width: 100%;
  text-align: start;
  font-size: 26px;
  font-weight: 700;
}

.section-line {
  width: fit-content;
}

.title {
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.title-static {
  color: whitesmoke;
}

.title-static2 {
  color: black;
}

.title:hover {
  color: #c39a3e;
}

.underline {
  width: 0%;
  background-color: #c39a3e;
  height: 3px;
  transition: 0.5s ease-in-out;
}

.underline-static {
  width: 100%;
  background-color: #c39a3e;
  height: 3px;
  transition: 0.5s ease-in-out;
}

.section-line:hover .underline {
  width: 100%;
}

.card-slider {
  padding: 20px 0 20px 0;
}

.controller {
  height: 100%;
  width: 5%;
}

.dividor {
  height: 2px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 40px;
}

.background-vid {
  width: 100vw;
  height: fit-content;
}

/* About Us Section */
.about-title > h1 {
  font-size: 100px;
  color: #c39a3e;
}

.about-us {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjusted for cross-browser support */
  width: 95vw;
  z-index: 1;
  top: 0;
}

.about-img {
  height: 300px;
  width: fit-content;
  border-radius: 50px 0 50px 0;
  filter: drop-shadow(10px 10px 10px #c39a3e);
}

.about-btn {
  background-color: #314152;
  padding: 10px 20px;
  font-size: 24px;
  margin-top: 10px;
  color: whitesmoke;
  width: fit-content;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.about-btn:hover {
  transform: translateX(-40px);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 1); /* Unified box-shadow syntax */
}

.text {
  width: 100%;
  height: 100%;
  font-size: 30px;
  color: white;
}

/* Notification Styling */
.notification {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 100%;
  background: #072d2a;
  border-radius: 1rem;
  font-size: 16px;
  --gradient: linear-gradient(to bottom, #072d2a, #072d2a, #d3b37d);
  --color: #d3b37d;
}

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background: #34514e;
  z-index: 2;
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 99%;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(-0.15rem);
}

.notititle {
  color: var(--color);
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  font-size: 4rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem);
}

.notibody {
  color: #99999d;
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.25rem);
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle closest-side at center,
    white,
    transparent
  );
  opacity: 0;
  transition: opacity 300ms ease;
}

.notification:hover .notiglow,
.notification:hover .notiborderglow {
  opacity: 0.1;
}

/* Service Section */
.service-image > img {
  width: auto;
  height: 300px;
}

.service-info {
  text-align: start;
}

ul {
  list-style: none;
  padding: 0px;
}

ul li:before {
  content: "\2713";
  margin: 0 1em;
}

.swiper-image {
  height: 400px;
  width: auto;
  cursor: pointer;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1025px) {
  .about-us {
    flex-direction: column;
    height: 50vh;
  }
  .about-title > h1 {
    font-size: 50px;
    text-align: center;
    margin-bottom: 30px;
  }
  .service-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .service-image > img {
    height: 180px;
  }
  .swiper-image {
    height: 180px;
  }
  .about-img {
    height: 300px;
    width: auto;
  }
}

@media screen and (max-width: 700px) {
  .about-us {
    flex-direction: column;
    height: 50vh;
  }
  .about-title > h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .about-img {
    height: 120px;
    width: auto;
  }

  .background-vid {
    width: fit-content;
    height: 300px;
  }
}

/* Overlay */
.image-overlay {
  position: relative;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
  color: whitesmoke;
  font-weight: 700;
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
}
</style>
