import { createStore } from "vuex";

export default createStore({
  state: {
    plan_id: 0,
    server: "https://1-tech-masters.com/Asool/public/api/",
    server_images: "https://1-tech-masters.com/Asool/public/app/public/",
    HDSserver: "https://www.1hds.net/hds/public/api/",
    HDSserver_images: "https://1hds.net/hds/public/app/public/",
    token: "",
    role: "",
    isLoading: 0,
  },
  getters: {},
  mutations: {
    hideLoadingScreen() {
      setTimeout(() => {
        // Remove the loading screen and enable animations
        const loader = document.getElementById("loader");
        if (loader) {
          loader.classList.add("fade"); // Add the fade-out class to start the animation
          loader.addEventListener("animationend", () => {
            loader.remove(); // Remove the element after the animation ends
          });
        }
        document.body.style.overflowY = "scroll"; // Re-enable scrolling
      }, 1000);
    },
    getAttr() {
      this.state.plan_id = 0;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has("plan_id")) {
        if (urlParams.get("plan_id") != this.state.plan_id) {
          this.state.plan_id = urlParams.get("plan_id");
        }
      }
    },
  },
  actions: {},
  modules: {},
});
