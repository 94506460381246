<template>
  <head>
    <title>{{ $t("dashboard.news") }}</title>
  </head>
  <div class="news-view">
    <SideBar>
      <div class="container bg-light h-100 overflow-scroll">
        <div class="d-flex justify-content-center align-items-center mt-5 mb-3">
          <div class="w-100 d-flex justify-content-start align-items-center">
            <h3>{{ $t("dashboard.news") }} ({{ data.length }})</h3>
          </div>
          <div class="w-100 d-flex justify-content-end align-items-center">
            <button
              type="button"
              class="add-btn"
              @click="$router.push('/admin-add-news')"
            >
              {{ $t("dashboard.AddNews") }}
            </button>
          </div>
        </div>
        <div class="search-bar w-100 my-4">
          <input
            type="text"
            class="my-inp w-50"
            style="border: 1px solid black"
            :placeholder="$t('dashboard.SearchNews')"
            v-model="search_for"
            v-on:input="SearchByName()"
          />
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t("dashboard.NameAr") }}</th>
                <th scope="col">{{ $t("dashboard.NameEn") }}</th>
                <th scope="col">{{ $t("dashboard.TextAr") }}</th>
                <th scope="col">{{ $t("dashboard.TextEn") }}</th>
                <th scope="col">{{ $t("dashboard.img") }}</th>
                <!-- <th scope="col">ملفات</th> -->
                <th scope="col">{{ $t("dashboard.delete") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shown_data" :key="index">
                <th scope="row">{{ item.id }}</th>
                <td>{{ item.title_ar }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.text_ar }}</td>
                <td>{{ item.text }}</td>
                <td>
                  <img
                    :src="$store.state.server_images + item.image"
                    alt=""
                    style="height: 100px; width: fit-content"
                  />
                </td>
                <!-- <td>
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="$router.push('/admin-add-files?id=' + item.id)"
                  >
                    إضافة
                  </button>
                </td> -->
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="deleteNews(item.id)"
                  >
                    {{ $t("dashboard.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "news-view",
  data() {
    return {
      data: [],
      shown_data: [],
      search_for: "",
    };
  },
  components: {
    SideBar,
  },
  methods: {
    getData() {
      axios
        .get(store.state.server + "news-index")
        .then((response) => {
          this.data = response.data;
          this.SearchByName();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SearchByName() {
      this.shown_data =
        this.search_for === ""
          ? this.data
          : this.data.filter((item) => item.name.includes(this.search_for));
    },
    deleteNews(id) {
      axios
        .get(store.state.server + "delete-news/" + id)
        .then((response) => {
          if (response.status == 200) {
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
