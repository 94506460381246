<template>
  <head>
    <title>{{ $t("Navbar.News") }}</title>
  </head>
  <div class="news-view">
    <div class="container p-2 hidden" v-in-view="'animate__slideInUp'">
      <div class="bg-whitesmoke my-5">
        <div class="page-title pt-5">{{ $t("Navbar.News") }}</div>
        <div class="row my-4 pb-5">
          <div class="col-lg-4" v-for="(item, index) in data" :key="index">
            <NewsCard
              class="m-3 hidden"
              v-in-view="'animate__backInUp'"
              :img="item.image"
              :id="item.id"
              :title="$i18n.locale === 'ar' ? item.title_ar : item.title"
              :text="$i18n.locale === 'ar' ? item.text_ar : item.text"
            ></NewsCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/NewsCard/NewsCards.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "news-view",
  components: {
    NewsCard,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    hideLoadingScreen() {
      store.commit("hideLoadingScreen");
    },
    readmore() {
      for (var key in this.data) {
        this.data[key].text = this.data[key].text.slice(0, 50);
        this.data[key].text += "...";
      }
    },
    getData() {
      axios
        .get(store.state.server + "news-index")
        .then((response) => {
          this.data = response.data;
          this.hideLoadingScreen();
          this.readmore();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  directives: {
    inView: {
      mounted(el, binding) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                el.classList.add("animate__animated", binding.value);
                el.classList.remove("hidden");
                observer.unobserve(el); // Stop observing
              }
            });
          },
          { threshold: 0.1 } // Trigger when 10% of the element is visible
        );
        observer.observe(el);
      },
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.page-title {
  padding: 10px;
  font-size: 40px;
  color: #c39a3e;
}

.page-content {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

@media screen and (max-width: 1025px) {
  .page-content {
    grid-template-columns: auto;
  }
}
</style>
