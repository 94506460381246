<template>
  <head>
    <title>{{ $t("dashboard.AddProject") }}</title>
  </head>
  <div class="add-product">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-start py-5"
        style="overflow-y: scroll"
      >
        <CardComponent :title="$t('dashboard.AddProject')">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="addProject()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="name_ar"
                  :placeholder="$t('dashboard.NameAr')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <input
                  type="text"
                  v-model="name_en"
                  :placeholder="$t('dashboard.NameEn')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <input
                  type="text"
                  v-model="live_link"
                  :placeholder="$t('Live')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <textarea
                  class="my-inp w-50"
                  :placeholder="$t('dashboard.TextAr')"
                  v-model="description_ar"
                  style="border: 1px solid black; height: 100px"
                ></textarea>
              </div>
              <div class="my-3">
                <textarea
                  class="my-inp w-50"
                  :placeholder="$t('dashboard.TextEn')"
                  v-model="description_en"
                  style="border: 1px solid black; height: 100px"
                ></textarea>
              </div>
              <div class="my-3">
                <select
                  @change="getIDtype()"
                  id="type"
                  v-model="type_name"
                  class="my-inp"
                  style="border: 1px solid black"
                >
                  <option
                    :value="
                      this.$i18n.locale === 'ar'
                        ? 'اختر نوع المشروع'
                        : 'Choose Project type'
                    "
                    disabled
                    selected
                  >
                    {{
                      this.$i18n.locale === "ar"
                        ? "اختر نوع المشروع"
                        : "ChooseProject type"
                    }}
                  </option>
                  <option
                    v-for="(item, index) in Types"
                    :key="index"
                    :value="$i18n.locale === 'ar' ? item.name_ar : item.name_en"
                    :id="item.id"
                  >
                    {{ $i18n.locale === "ar" ? item.name_ar : item.name_en }}
                  </option>
                </select>
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.services") }}</h3>
                <div class="row row-cols-auto">
                  <div
                    class="col-4 d-flex justify-content-center align-items-center"
                    v-for="(item, index) in servicesData"
                    :key="index"
                  >
                    <input type="checkbox" @click="AddRemoveService(item.id)" />
                    <Icon :icon="item.icon" heighet="24" width="24" />
                    {{ $i18n.locale === "ar" ? item.name_ar : item.name_en }}
                  </div>
                </div>
              </div>
              <div class="my-3">
                <h3>{{ $t("Plans") }}</h3>
                <div class="row row-cols-auto">
                  <div
                    class="col-6 d-flex justify-content-center align-items-center"
                    v-for="(item, index) in plans_data"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      class="ms-3"
                      @click="AddRemovePlan(item.id)"
                    />
                    <div class="bgImgPlan">
                      <img
                        :src="$store.state.server_images + item.image"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.cover") }}</h3>
                <input
                  type="file"
                  id="src"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.ProjectImages") }}</h3>
                <input
                  type="file"
                  id="images"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                  multiple
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.videos") }}</h3>
                <input
                  type="file"
                  id="videos"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="video/*"
                  multiple
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("Certificates") }}</h3>
                <input
                  type="file"
                  id="certificates"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                  multiple
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("expenses") }}</h3>
                <input
                  type="file"
                  id="payment"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  multiple
                />
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">إضافة</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div class="title">جاري التحميل...</div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { Icon } from "@iconify/vue";
export default {
  name: "add-product",
  data() {
    return {
      name_ar: "",
      name_en: "",
      description_ar: "",
      description_en: "",
      live_link: "",
      type_id: "",
      plans: [],
      services: [],

      Types: [],
      servicesData: [],
      plans_data: [],
      name_pay_ar: "",
      name_pay_en: "",
      type_name:
        this.$i18n.locale === "ar" ? "اختر نوع المشروع" : "Choose Project type",
      is_discount: 0,
      discount_value: "",
      dis: "none",
      Zindex: -3,
    };
  },
  components: {
    SideBar,
    CardComponent,
    Icon,
  },
  methods: {
    addProject() {
      if (
        this.name_ar === "" ||
        this.name_en === "" ||
        this.description_ar === "" ||
        this.description_en === "" ||
        this.type_id === "" ||
        this.services.length === 0 ||
        this.plans.length === 0
      ) {
        alert("الرجاء تعبئة الحقول المطلوبة");
        return;
      }

      const formData = new FormData();
      const imgFile = document.querySelector("#src");

      if (imgFile.files.length == 0) {
        alert("قم بإدخال صورة الغلاف");
        return;
      }

      formData.append("image", imgFile.files[0]);
      formData.append("name_ar", this.name_ar);
      formData.append("name_en", this.name_en);
      formData.append("description_ar", this.description_ar);
      formData.append("description_en", this.description_en);
      formData.append("live_link", this.live_link);
      formData.append("type_id", this.type_id);

      for (let i = 0; i < this.services.length; i++) {
        formData.append(`services[${i}]`, this.services[i]);
      }

      for (let i = 0; i < this.plans.length; i++) {
        formData.append(`plans[${i}]`, this.plans[i]);
      }

      const imgFiles = document.querySelector("#images");
      const imgsData = imgFiles.files;

      if (imgsData.length > 0) {
        for (let i = 0; i < imgsData.length; i++) {
          formData.append(`images[${i}]`, imgsData[i]);
        }
      }

      const videosFiles = document.querySelector("#videos");
      const videosData = videosFiles.files;

      if (videosData.length > 0) {
        for (let i = 0; i < videosData.length; i++) {
          formData.append(`videos[${i}]`, videosData[i]);
        }
      }

      const certificatesFiles = document.querySelector("#certificates");
      const certificatesData = certificatesFiles.files;

      if (certificatesData.length > 0) {
        for (let i = 0; i < certificatesData.length; i++) {
          formData.append(`certificates[${i}]`, certificatesData[i]);
        }
      }

      const payments = document.querySelector("#payment");
      const paymentsData = payments.files;

      if (paymentsData.length > 0) {
        for (let i = 0; i < paymentsData.length; i++) {
          formData.append(`payments[${i}]`, paymentsData[i]);
        }
      }

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      axios
        .post(store.state.server + "add-project", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تمت الإضافة بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-projects");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
    getType() {
      axios
        .get(store.state.server + "get-all-types")
        .then((response) => {
          this.Types = response.data;
          this.getServices();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getServices() {
      axios
        .get(store.state.server + "get-all-services")
        .then((response) => {
          this.servicesData = response.data;
          this.getPlans();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlans() {
      axios
        .get(store.state.server + "get-all-plans")
        .then((response) => {
          this.plans_data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getID() {
      const element = document.getElementById("discounts");
      for (let i = 0; i < element.length; i++) {
        if (element[i].value == this.discount_name) {
          this.discount_id = element[i].id;
          this.discount_value = this.discounts[i - 1].value;
          this.ChangePrice();
          break;
        }
      }
    },
    getIDtype() {
      const element = document.getElementById("type");
      for (let i = 0; i < element.length; i++) {
        if (element[i].value == this.type_name) {
          this.type_id = element[i].id;
          break;
        }
      }
    },
    AddRemoveService(id) {
      const index = this.services.indexOf(id);
      if (index > -1) {
        this.services.splice(index, 1); // Remove the element at the specified index
      } else {
        this.services.push(id); // Add the element if it's not in the array
      }
    },
    AddRemovePlan(id) {
      const index = this.plans.indexOf(id);
      if (index > -1) {
        this.plans.splice(index, 1); // Remove the element at the specified index
      } else {
        this.plans.push(id); // Add the element if it's not in the array
      }
    },
  },
  mounted() {
    this.getType();
  },
};
</script>

<style scoped>
.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 40px;
  font-weight: 700;
}

.bgImgPlan > img {
  width: 150px;
  height: fit-content;
}
</style>
