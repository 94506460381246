<template>
  <head>
    <title>{{ $t("dashboard.users") }}</title>
  </head>
  <div class="users-view">
    <SideBar>
      <div class="container bg-light h-100 overflow-scroll">
        <div class="d-flex justify-content-center align-items-center mt-5 mb-3">
          <div class="w-100 d-flex justify-content-start align-items-center">
            <h3>{{ $t("dashboard.users") }} ({{ data.length }})</h3>
          </div>
          <div class="w-100 d-flex justify-content-end align-items-center">
            <button
              type="button"
              class="add-btn"
              @click="$router.push('/admin-add-user')"
            >
              {{ $t("dashboard.AddUser") }}
            </button>
          </div>
        </div>
        <div class="search-bar w-100 my-4">
          <input
            type="text"
            class="my-inp w-50"
            style="border: 1px solid black"
            :placeholder="$t('dashboard.SearchUsers')"
            v-model="search_for"
            v-on:input="SearchByName()"
          />
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t("dashboard.name") }}</th>
                <th scope="col">{{ $t("dashboard.phone") }}</th>
                <th scope="col">{{ $t("dashboard.AccType") }}</th>
                <th scope="col">{{ $t("dashboard.accState") }}</th>
                <th scope="col">{{ $t("dashboard.changeAcc") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shown_data" :key="index">
                <th scope="row">{{ item.id }}</th>
                <td>{{ item.fname + " " + item.lname }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  {{
                    item.role == "admin" ? $t("login.Admin") : $t("login.user")
                  }}
                </td>
                <td>
                  {{
                    item.isActive == 1
                      ? $t("dashboard.active")
                      : $t("dashboard.block")
                  }}
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning"
                    @click="ChangeStauts(item.id)"
                  >
                    تغيير حالة الحساب
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "users-view",
  data() {
    return {
      data: [],
      shown_data: [],
      search_for: "",
    };
  },
  components: {
    SideBar,
  },
  methods: {
    getData() {
      axios
        .get(store.state.server + "get-all-users")
        .then((response) => {
          this.data = response.data;
          this.SearchByName();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SearchByName() {
      this.shown_data =
        this.search_for === ""
          ? this.data
          : this.data.filter((item) =>
              (item.fname + " " + item.lname)
                .toLowerCase()
                .includes(this.search_for.toLowerCase())
            );
    },
    ChangeStauts(id) {
      axios
        .get(store.state.server + "change-user-status/" + id)
        .then((response) => {
          if (response.status == 200) {
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
