<template>
  <head>
    <title>
      {{ $t("Navbar.team") }}
    </title>
  </head>
  <div class="our-team mt-4 bg-light">
    <div
      v-for="(item, index) in data[$i18n.locale]"
      class="row p-5 w-100 animate-on-scroll"
      :key="index"
    >
      <div
        class="col-lg-6 service-image"
        v-if="index % 2 == 0 || (index % 2 != 0 && screen_width <= 1025)"
      >
        <img :src="item.img" :alt="item.name" />
      </div>
      <div class="col-lg-6 service-info">
        <h3>{{ item.name }}</h3>
        <h5>{{ item.position }}</h5>
        <ul class="mt-5">
          <li
            class="mt-4"
            v-for="(item2, index2) in item.messages"
            :key="index2"
          >
            {{ item2 }}
          </li>
        </ul>
      </div>
      <div
        class="col-lg-6 service-image"
        v-if="index % 2 != 0 && screen_width >= 1025"
      >
        <img :src="item.img" :alt="item.name" />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "our-team",
  data() {
    return {
      screen_width: screen.width,
      data: {
        ar: {
          0: {
            name: "المهندس حسن بن محمد حسن بن علي نحاس",
            position: "الرئيس التنفيذي لشركة أصول بلس العقارية",
            img: require("@/assets/Team/team-1.jpg"),
            messages: {
              0: "في أصول بلس، قصتنا هي قصة شغف وتفان وإيمان راسخ بإمكانات المملكة العربية السعودية. منذ بداياتنا المتواضعة، نمونا لنصبح اسماً موثوقاً في عالم المقاولات والعقارات، مدفوعين برغبة عميقة في بناء ليس فقط المشاريع، بل الأحلام والإرث المستدام.",
              1: "رؤيتنا متجذرة في المساهمة بنهضة المملكة، بما يتماشى مع رؤية ،2030 من خلال تطوير مجتمعات حيوية ومشاريع مبتكرة تكون رمًزا للتقدم والفرص. كل خطوة نخطيها مستوحاة من رغبتنا في إحداث تأثير حقيقي، وخلق مساحات تلهم، وتجمع الناس، وتستمر للأجيال القادمة.",
              2: "نحن فخورون برحلتنا، ولكن أعيننا دائماً على المستقبل. أهدافنا وطموحنا: أن نقود الابتكار، ونتبنى الاستدامة، ونتعاون مع عملائنا وشركائنا لنقدم التميز في كل مشروع. في جوهر كل ما نقوم به، هناك حقيقة واحدة بسيطة، نحن نبني بشغف، برؤية، وبإيمان قوي بما يمكننا تحقيقه معًا.",
              3: "شكًرا لكونكم جزءاً من رحلتنا، ونتطلع إلى بناء مستقبل أكثر إشراقاً معكم.",
            },
          },
          1: {
            name: "السيدة / دلال يحيى عبد الحليم منديلي",
            position: "الرئيس التنفيذي الثاني، أصول بلس",
            img: require("@/assets/Team/team-2.jpg"),
            messages: {
              0: "بصفتي امرأة تقود أصول بلس، أفخر بأن أكون جزءاً من التحول الذي تشهده المملكة العربية السعودية، حيث أصبحت المرأة قوة مؤثرة في مختلف القطاعات وتدفع عجلة التقدم. وتحت مظلة رؤية ،2030 تجاوزنا الحواجز وأثبتنا أن الموهبة والقيادة ال تعرفان حدوداً.",
              1: "في أصول بلس، نحن لا نبني مجرد مشاريع، بل نبني مستقبلاً قائماً على الابتكار والتميز والشمولية. فنحن نؤمن بأن التنوع هو مصدر قوتنا، وأن تمكين المرأة يعزز من نجاحنا ويخلق قطاعاً أكثر تطوراً وديناميكية.",
              2: "نحن اليوم في عصر جديد، حيث يحدد الطموح والعمل الجاد معايير النجاح. معاً، نبني مستقبلاً تكون فيه الفرص متاحة للجميع للقيادة والابتكار والازدهار.",
            },
          },
          2: {
            name: "الأستاذ سامر رمضان",
            position: "نائب الرئيس التنفيذي، أصول بلس",
            img: require("@/assets/Team/team-3.jpg"),
            messages: {
              0: "في أصول بلس، تكمن قوتنا في فريقنا، هيكلنا المنظم، والتزامنا بالتميز باعتبارنا رواد في قطاعي المقاولات والعقارات في المملكة العربية السعودية، ونحرص على أن يعكس كل مشروع خبرتنا وابتكارنا وأعلى معايير الجودة.",
              1: "تضمن أقسامنا المتخصصة تنفيذ كل مرحلة من مراحل التطوير بكفاءة ودقة واستدامة. وبفضل فريقنا المحترف، نعزز ثقافة التعاون والتطوير المستمر، مما يمكننا من تقديم مشاريع تدوم عبر الأجيال.",
              2: "الجودة هي الأساس في كل ما نقوم به. بد ءاً من المواد وصولاً إلى التنفيذ، نلتزم بأعلى المعايير في أصول بلس، لا نبني الهياكل فحسب، بل نبني الثقة، والجودة والإبداع، وإرثاً من التميز.",
              3: "نتطلع إلى مواصلة النمو مع عملائنا وشركائنا المميزين. شكًرا لثقتكم ودعمكم.",
            },
          },
        },
        en: {
          0: {
            name: "Eng. Hassan Bin Mohammed Hassan Bin Ali Nahaas",
            position: "CEO, Asool Plus Real Estate Company",
            img: require("@/assets/Team/team-1.jpg"),
            messages: {
              0: "At Asool Plus, Our Story is One of Passion, Dedication, and a Strong Belief in the Potential of Saudi Arabia. From our humble beginnings, we have grown into a trusted name in the contracting and real estate sector, driven by a deep desire to build not just projects, but dreams and a lasting legacy.",
              1: "Our vision is rooted in contributing to the Kingdom’s progress, in alignment with Vision 2030, by developing vibrant communities and innovative projects that serve as symbols of growth and opportunity. Every step we take is inspired by our commitment to making a real impact—creating spaces that inspire, bring people together, and endure for generations to come.",
              2: "We take pride in our journey, yet our eyes remain firmly set on the future. Our ambition is to lead innovation, embrace sustainability, and collaborate with our clients and partners to deliver excellence in every project. At the core of every-thing we do lies a simple truth: we build with passion, vision, and a strong belief in what we can achieve together.",
              3: "Thank you for being part of our journey. We look forward to building a brighter future with you.",
            },
          },
          1: {
            name: "Mrs. Dalal Yahya Abdulhalim Mandili",
            position: "Second CEO, Asool Plus",
            img: require("@/assets/Team/team-2.jpg"),
            messages: {
              0: "As a woman leading Asool Plus, I am proud to be part of Saudi Arabia's transformation.",
              1: "Today, women have become a driving force across various sectors, pushing progress forward. Under the umbrella of Vi-sion 2030, we have broken barriers and proven that talent and leadership know no limits.",
              2: "At Asool Plus, we do not just build projects—we build a future based on innovation, excellence, and inclusivity. We believe that diversity is our strength and that empowering women enhances our success, creating a more advanced and dynamic industry.",
              3: "We are living in a new era where ambition and hard work define success. Together, we are building a future where oppor-tunities are open for everyone to lead, innovate, and thrive.",
            },
          },
          2: {
            name: "Mr. Samer Ramadan",
            position: "Vice CEO, Asool Plus",
            img: require("@/assets/Team/team-3.jpg"),
            messages: {
              0: "At Asool Plus, our strength lies in our team, structured approach, and commitment to excellence.",
              1: "As leaders in Saudi Arabia’s contracting and real estate sectors, we ensure that every project reflects our expertise, inno-vation, and the highest standards of quality.",
              2: "Our specialized divisions oversee every phase of development with efficiency, precision, and sustainability. With a team of dedicated professionals, we foster a culture of collaboration and continuous growth, enabling us to deliver projects that endure for generations.",
              3: "Quality is the foundation of everything we do. From materials to execution, we adhere to the highest industry standards. At Asool Plus, we don’t just build structures—we build trust, quality, creativity, and a legacy of excellence.",
              4: "We look forward to continued growth alongside our valued clients and partners. Thank you for your trust and support.",
            },
          },
        },
      },
    };
  },

  methods: {
    hideLoadingScreen() {
      store.commit("hideLoadingScreen");
      store.state.isLoading = 1;
    },
    observeElements() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);
      const elements = document.querySelectorAll(".animate-on-scroll");
      elements.forEach((element) => {
        observer.observe(element);
      });
    },
  },
  mounted() {
    store.state.isLoading = 0;
    this.hideLoadingScreen();
    this.observeElements();
  },
};
</script>

<style scoped>
/* Service Section */
.service-image > img {
  width: auto;
  height: 500px;
}

.service-info {
  text-align: start;
}

ul {
  list-style: none;
  padding: 0px;
}

ul li:before {
  content: "\2713";
  margin: 0 1em;
}

/* Animation */
.animate-on-scroll {
  opacity: 0;
  transform: translateY(45px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.animate-on-scroll.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1025px) {
  .service-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .service-image > img {
    height: 250px;
  }
}
</style>
