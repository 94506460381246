<template>
  <head>
    <title>{{ $t("dashboard.Messages") }}</title>
  </head>
  <div class="admin-home">
    <SideBar>
      <div class="container bg-light h-100 overflow-scroll">
        <div class="d-flex justify-content-center align-items-center mt-5 mb-3">
          <div class="w-100 d-flex justify-content-start align-items-center">
            <h3>{{ $t("dashboard.Messages") }} ({{ data_messages.length }})</h3>
          </div>
        </div>
        <div class="search-bar w-100 my-4">
          <input
            type="text"
            class="my-inp w-50"
            style="border: 1px solid black"
            :placeholder="$t('dashboard.SearchUsers')"
            v-model="search_for"
            v-on:input="SearchByName()"
          />
        </div>
        <!-- <div class="display-grid mt-lg-5 mt-3 mb-3">
          <div class="card" v-for="(item, index) in data" :key="index">
            <div class="card-title">
              {{
                index == "users"
                  ? "المستخدمين"
                  : index == "items"
                  ? "المنتجات"
                  : index == "materials"
                  ? "المواد"
                  : index == "colors"
                  ? "الألوان"
                  : index == "sub_categories"
                  ? "الأنواع"
                  : index == "categories"
                  ? "الأصناف"
                  : index == "sections"
                  ? "الأقسام"
                  : "الطلبات"
              }}
            </div>
            <div class="card-body">{{ item }}</div>
          </div>

        </div> -->
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t("dashboard.name") }}</th>
                <th scope="col">{{ $t("dashboard.phone") }}</th>
                <th scope="col">{{ $t("ContactUs.email") }}</th>
                <th scope="col">{{ $t("dashboard.Messages") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shown_data" :key="index">
                <th scope="row">{{ item.id }}</th>
                <td>{{ item.fname + " " + item.lname }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import axios from "axios";
import store from "@/store";
export default {
  name: "admin-home",
  data() {
    return {
      data: [],
      data_messages: [],
      shown_data: [],
      search_for: "",
    };
  },
  components: { SideBar },
  methods: {
    getData() {
      // axios
      //   .get(store.state.server + "counting")
      //   .then((response) => {
      //     this.data = response.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      axios
        .get(store.state.server + "get-messages")
        .then((response) => {
          this.data_messages = response.data.data;
          this.SearchByName();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SearchByName() {
      this.shown_data =
        this.search_for === ""
          ? this.data_messages
          : this.data_messages.filter((item) =>
              (item.fname + " " + item.lname)
                .toLowerCase()
                .includes(this.search_for.toLowerCase())
            );
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.add-btn {
  height: 50px;
  width: fit-content;
  padding: 10px 50px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  background-color: #c39a3e;
  color: whitesmoke;
  transition: 0.5s ease-in-out;
}

.add-btn:hover {
  background-color: #ab8731;
}

.my-card {
  background-color: rgba(49, 65, 82, 0.9);
  border-radius: 10px;
  width: 500px;
  color: whitesmoke;
  padding: 40px 0;
}

.color_circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid black;
}

.display-grid {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: start;
}

.overflow-scroll {
  overflow-y: scroll;
}

@media screen and (max-width: 1286px) {
  .display-grid {
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style scoped>
.card {
  padding: 10px;
  transition: 0.5s ease-in-out;
}

.card-title {
  font-size: 24px;
  font-weight: 700;
}

.card-body {
  font-size: 18px;
  font-weight: 500;
}

.card:hover {
  color: #c39a3e;
}

@media screen and (max-width: 566px) {
  .card {
    width: 250px;
  }
}
</style>
