<template>
  <head>
    <title>{{ $t("dashboard.AddUser") }}</title>
  </head>
  <div class="add-user">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-start py-5"
        style="overflow-y: scroll"
      >
        <CardComponent :title="$t('dashboard.AddUser')">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="addUser()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="fname"
                  :placeholder="$t('ContactUs.fname')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="d-block w-100">
                <div class="my-3">
                  <input
                    type="text"
                    v-model="lname"
                    :placeholder="$t('ContactUs.lname')"
                    class="my-inp w-50"
                    style="border: 1px solid black"
                  />
                </div>
              </div>
              <div class="d-block w-100">
                <div class="my-3">
                  <input
                    type="email"
                    v-model="email"
                    :placeholder="$t('ContactUs.email')"
                    class="my-inp w-50"
                    style="border: 1px solid black"
                  />
                </div>
              </div>
              <div class="d-block w-100">
                <div class="my-3">
                  <input
                    type="text"
                    v-model="phone"
                    :placeholder="$t('ContactUs.phone')"
                    class="my-inp w-50"
                    style="border: 1px solid black"
                  />
                </div>
              </div>
              <div class="d-block w-100">
                <div class="my-3 password">
                  <input
                    :type="inp_type"
                    v-model="password"
                    :placeholder="$t('login.password')"
                    class="my-inp w-50"
                    style="border: 1px solid black"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="eye"
                    @click="show_hide_password()"
                  >
                    <path fill="#000000" :d="path" />
                  </svg>
                </div>
              </div>
              <div class="d-block w-100">
                <div class="my-3 password">
                  <input
                    :type="inp_type2"
                    v-model="confPassword"
                    :placeholder="$t('login.confPassword')"
                    class="my-inp w-50"
                    style="border: 1px solid black"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="eye"
                    @click="show_hide_password2()"
                  >
                    <path fill="#000000" :d="path2" />
                  </svg>
                </div>
              </div>
              <div class="d-block w-100">
                <h3>
                  {{ $t("login.AccType") }}
                </h3>
                <div
                  class="my-3 d-flex justify-content-center align-items-center"
                >
                  <input type="radio" v-model="role" value="admin" />
                  <span class="mx-3">{{ $t("login.Admin") }}</span>
                  <input
                    type="radio"
                    v-model="role"
                    value="user"
                    class="mx-3"
                  />
                  <span>{{ $t("login.user") }}</span>
                </div>
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">
                  {{ $t("dashboard.add") }}
                </button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div class="title">{{ $t("dashboard.loading") }}</div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-user",
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      password: "",
      confPassword: "",
      role: "",
      path: "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5",
      path2:
        "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5",
      Zindex: -3,
      inp_type: "password",
      inp_type2: "password",
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    show_hide_password() {
      if (this.inp_type == "password") {
        this.inp_type = "text";
        this.path =
          "M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7";
      } else {
        this.inp_type = "password";
        this.path =
          "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5";
      }
    },
    show_hide_password2() {
      if (this.inp_type2 == "password") {
        this.inp_type2 = "text";
        this.path2 =
          "M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7";
      } else {
        this.inp_type2 = "password";
        this.path2 =
          "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5";
      }
    },
    addUser() {
      if (
        this.fname === "" ||
        this.lname === "" ||
        this.email === "" ||
        this.phone === "" ||
        this.password === "" ||
        this.confPassword === "" ||
        this.role === ""
      ) {
        alert(
          this.$i18n.locale === "ar"
            ? "الرجاء تعبئة الحقول المطلوبة"
            : "Please fill all the required fields"
        );
        return;
      } else if (this.phone.search(/^[+][1-9]{1,4}[- 0-9]{3,12}$/) < 0) {
        alert(
          this.$i18n.locale === "ar"
            ? "هذا ليس رقم هاتف"
            : "That's not a phone number"
        );
        return;
      } else if (this.email.search(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/) < 0) {
        alert(
          this.$i18n.locale === "ar" ? "هذا ليس إيميل" : "That's not an email"
        );
        return;
      } else if (this.password != this.confPassword) {
        alert(
          this.$i18n.locale === "ar"
            ? "كلمة المرور غير متطابقة"
            : "Passwords doesn't match"
        );
        return;
      }
      const formData = new FormData();

      formData.append("fname", this.fname);
      formData.append("lname", this.lname);
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("phone", this.phone);
      formData.append("role", this.role);

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      axios
        .post(store.state.server + "register", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تمت الإضافة بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-users");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
  },
};
</script>

<style scoped>
.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 40px;
  font-weight: 700;
}

.password {
  position: relative;
}

.eye {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 130px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
</style>
