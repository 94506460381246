<template>
  <div class="project-cards">
    <div class="my-product-card">
      <div class="img-container">
        <img :src="img" alt="" />
      </div>
      <div class="my-card-body">
        <div class="news-title text-center">
          {{ title }}
        </div>
      </div>
      <div class="card-footer">
        <router-link :to="'/project-info?id=' + id" class="read-more w-100">
          <div class="mx-1">{{ $t("KnowMore") }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project-cards",
  props: ["img", "title", "id"],
};
</script>

<style scoped>
.my-product-card {
  position: relative;
  background-color: white;
  padding: 10px;
  width: auto;
  height: 300px;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.my-product-card:hover {
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transform: translateY(-20px);
  background-color: #072d2a;
}

.img-container {
  height: auto;
  width: fit-content;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-container > img {
  width: auto;
  max-width: 100%; /* Ensures the image scales properly */
  height: 190px;
  border: 1px solid rgba(100, 100, 100, 0.3);
}

.my-card-body {
  width: 80%;
  margin-right: 20px;
}

.news-title {
  color: #c39a3e;
  font-size: 30px;
  font-weight: bold;
  text-align: center; /* Ensures proper alignment */
}

.news-body {
  font-size: 18px;
  text-align: justify; /* Improves readability */
}

.card-footer {
  width: 60%;
  margin-right: 20px;
}

.read-more {
  text-decoration: none;
  background-color: unset;
  padding: 10px 20px;
  border: 1px solid rgba(195, 155, 62, 0.5);
  color: #808080;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 600; /* Slightly bold for visibility */
}

.read-more:hover {
  transform: translateY(-10px);
  background-color: #c39a3e;
  color: whitesmoke;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .my-product-card {
    height: auto; /* Adjusts dynamically for content */
    padding: 15px;
  }

  .img-container > img {
    width: 100%; /* Scales image to card width */
    height: auto;
  }

  .my-card-body,
  .card-footer {
    width: 100%;
    margin-right: 0;
  }

  .news-title {
    font-size: 24px;
  }

  .news-body {
    font-size: 16px;
  }

  .read-more {
    padding: 8px 16px;
    font-size: 14px; /* Scales text for smaller screens */
  }
}
</style>
