<template>
  <div class="card-component">
    <div class="my-card">
      <h1>{{ title }}</h1>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "card-component",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.my-card {
  background-color: #072d2a;
}
</style>
